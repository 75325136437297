import React from "react";
import type { NextPage } from "next";
import { useEffect } from "react";
import { Text, Box } from "@chakra-ui/react";
import strings from "~/resources/strings";

const NotFound: NextPage = () => {
	const pageStrings = strings.screen.notFound;

	useEffect(() => {
		if (process.env.NODE_ENV === "production") {
			setTimeout(() =>
				location.href = "/",
			3000,
			);
		}
	}, []);

	return (
		<Box
			h="100vh"
			display="flex"
		>
			<Box alignSelf="center" margin={["5", 0]} ml={["5", "5", "20", "80"]}>
				<Text
					fontSize={["4xl","5xl"]}
					color="primary.700"
					fontWeight="bold"
				>
					{pageStrings.title}
				</Text>
				<Text
					fontSize={["md", "xl"]}
					color="secondary.600"
				>
					{pageStrings.helper}
				</Text>
			</Box>
		</Box>
	);
};

export default NotFound;
